<template>
  <div>
    <div v-if="false" class="columns reader-nav is-vcentered is-mobile px-5">
      <div class="column">
        <a @click="$router.go(-1)">
          <b-button
            type="is-dark"
            icon-left="arrow-left"
            outlined
            rounded
            class="mt-5"
          >
            Back
          </b-button>
        </a>
      </div>
      <div class="column is-narrow">
        <router-link
          :to="{
            name: 'Home'
          }"
        >
          <img src="@/assets/logo.png" style="height: 4vh" class="mt-5" />
        </router-link>
      </div>
      <div class="column"></div>
    </div>

    <div
      v-if="
        item && item.premiumContent && !fullScreen && currentUser.role == 'free'
      "
      class="columns m-5"
    >
      <div class="column">
        <b-message type="is-info">
          <div class="columns is-vcentered">
            <div class="column">
              <b-icon icon="lock" class="pr-2" size="is-small"></b-icon>
              Premium Content - Sign in as a Researcher to gain access
            </div>
            <div v-if="false" class="column">
              <b-icon icon="lock" class="pr-2" size="is-small"></b-icon>
              Premium Content - Upgrade your account to Researcher to gain
              access
            </div>
            <div class="column is-narrow">
              <router-link :to="{ name: 'Login' }">
                <b-button type="is-info">Sign In</b-button>
              </router-link>
            </div>
          </div>
        </b-message>
      </div>
    </div>
    <div v-if="item">
      <div id="pdf-view"></div>
    </div>
    <div v-if="pdfLoading">
      <Loading />
    </div>
  </div>
</template>

<script>
import api from "@/services/dataService";
import Loading from "@/components/app/Loading";

const ADOBE_KEY = "6742815e48144662a47f8d467767f385";

export default {
  name: "EBookReader",
  components: { Loading },
  data() {
    return {
      // pdf api - start
      pdfAPIReady: false,
      adobeDCView: null,
      pdfSelected: false,
      pdfLoading: true,
      // pdf api - end
      showPDF: true,
      // pdf viewer variables
      fullScreen: false,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      show: true,
      // others
      itemId: undefined,
      type: undefined,
      item: undefined,
      hiddenTags: [
        "title",
        "description",
        "keywords",
        "authors",
        "kidsContent",
        "premiumContent",
        "dateCreated",
        "lastUpdated",
        "paidContent",
        "type",
        "coverPhoto",
        "__v",
        "_id",
        "attachmentURL",
        "paid",
        "featured"
      ]
    };
  },
  watch: {
    pdfAPIReady(val) {
      // should only be called when true, but be sure
      if (val) {
        // eslint-disable-next-line no-undef
        this.adobeDCView = new AdobeDC.View({
          clientId: ADOBE_KEY,
          divId: "pdf-view"
        });
      }
    },
    resourceURL(val) {
      // should only be called when true, but be sure
      if (val) {
        this.previewPDF();
      }
    }
  },
  async mounted() {
    this.pdfAPIReady = true;
  },
  computed: {
    resourceURL() {
      return this.$store.state.resourceUrl;
    },
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  created() {
    // start - pdf api

    if (window.AdobeDC) this.pdfAPIReady = true;
    // end -pdf api
    if (this.$route.query.id) {
      this.itemId = this.$route.query.id;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getItemDetail();
  },
  beforeDestroy() {
    document.getElementById("pdf-view").innerHTML = "";
    document.getElementById("pdf-view").removeAttribute("style");
  },
  methods: {
    // start - pdf api

    previewPDF() {
      let viewer = this.adobeDCView;
      viewer.previewFile(
        {
          content: {
            location: {
              url: this.resourceURL
            }
          },
          metaData: { fileName: this.item.title }
        },
        {
          showAnnotationTools: false,
          showDownloadPDF: false,
          showPrintPDF: false
        }
      );
      this.pdfLoading = false;
    },
    // end -pdf api
    getItemDetail() {
      api
        .get(this.generateGetDetailUrl(this.type) + this.itemId)
        .then(res => {
          // sorting alphabetically and adding in item
          this.item = Object.keys(res.data.data)
            .sort()
            .reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});
          // fetching PDF Url if resource is E type (URLs generated at runtime through s3)
          const request = {
            resourceId: this.item._id,
            resourceType: this.type
          };
          this.$store.dispatch("setResourceUrl", undefined);
          this.$store.dispatch("getResourceUrl", request);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    async toggleFullscreen() {
      this.fullScreen = !this.fullScreen;
      this.showPDF = false;
      this.page = this.page + 1;
      await new Promise(r => setTimeout(r, 200));
      this.page = this.page - 1;
      this.showPDF = true;
    },
    generateGetDetailUrl(type) {
      if (type == "PrintedBook") return "/books/printed-book/";
      if (type == "eBook") return "/books/e-book/";
      if (type == "Research") return "/research/research-article/";
      if (type == "eResearch") return "/research/eresearch-article/";
      if (type == "Journal") return "/journal/journal-article/";
      if (type == "eJournal") return "/journal/ejournal-article/";
      if (type == "Newspaper") return "/newspaper/newspaper-article/";
      if (type == "eNewspaper") return "/newspaper/eNewspaper-article/";
      if (type == "image") return "/digital/digital-media/";
      if (type == "video") return "/digital/digital-media/";
      if (type == "audio") return "/digital/digital-media/";
      if (type == "document") return "/digital/digital-media/";
      if (type == "dataset") return "/digital/digital-media/";
      if (type == "map") return "/digital/digital-media/";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.explore-box {
  background-color: #f8f8f8 !important;
}
#pdf-controls {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 10;
}
.full-height-reader {
  display: inline-block;
  width: 40%;
}
.regular-reader {
  display: inline-block;
  width: 100%;
}
#pdf-view {
  position: absolute;
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: -10;
}
.reader-nav {
  height: 0vh;
  width: 100vw;
}
</style>
